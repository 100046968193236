// add auto complete to delay code entry
function addAutocomplete() {
  $('.ui-autocomplete-input').each(function () {
    const inputField = $(this);
    inputField.autocomplete({
      source: inputField.data('autocomplete-source'),
      select: function (event, ui) {
        inputField.val(ui.item.label);
        // find the related hidden field
        $(`#${this.id.replace('name', 'id')}`).val(ui.item.value);

        return false;
      }
    });
  });
}

window.addAutocomplete = addAutocomplete;

document.addEventListener("turbo:load", () => {
  const addFields = document.getElementsByClassName('add-fields');

  [...addFields].forEach((item) => {
    item.addEventListener('click', (event) => {
      event.preventDefault();

      const fields = document.getElementsByClassName('fields')[0];

      // only allow 4 delay codes
      if ([...fields.children].filter((tag) => tag.tagName === 'DIV' && !tag.classList.contains('d-none')).length >= 4) {
        alert("Unable to add more than 4 delay codes.")
        return;
      }

      let formFields = event.target.dataset.fields;
      const regexp = new RegExp(event.target.dataset.id, 'g');
      const time = new Date().getTime();

      fields.insertAdjacentHTML('beforeend', formFields.replace(regexp, time));

      // add autocomplete to fields
      addAutocomplete()
    })
  })
})
