import "@hotwired/turbo-rails"
import * as bootstrap from "bootstrap"
import "trix";
import "@rails/actiontext";

// this fixes javascript hoisting
import "./src/jquery"
// using a custom download for just the autocomplete that we use
import "./src/jquery-ui";

import "./autocomplete"
import "./combobox"
import "./time_zone_change"

$(function() {
  console.log("hello from application.js")
})

document.addEventListener("turbo:load", function () {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
})
