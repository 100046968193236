function toLocalTZ(utcTime) {
  let dateTime = new Date(utcTime);
  let date = dateTime.toLocaleDateString('en-US', {month: 'short', day: '2-digit', year: 'numeric'});
  let time = dateTime.toLocaleTimeString('en-US', {hour12: true, hour: '2-digit', minute: '2-digit'});
  return (`${date} ${time}`);
}

function reloadTimes() {
  let times = document.getElementsByClassName("to-local-time-zone");
  Array.from(times).forEach((el) => el.innerText = toLocalTZ(el.innerText));
}

function setValueCurrentDate(el) {
  el.value = new Date().toISOString().split("T")[0];
}

document.addEventListener('turbo:load', function () {
  reloadTimes();
})
